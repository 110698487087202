/// <reference path="../../main.scss" />

.tnew-container {
    .tn-contribution-component {
        .form-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;

            .tn-required-field {
                float: none;
                align-self: flex-end;
            }
        }
    }

    .has-error {
        .tn-required-field {
            font-weight: $font-weight-bold;
            color: $tnew-error;
        }
        .control-label {
            color: $dark-blue;

            &:before {
                display: none;
            }
        }
    }
}