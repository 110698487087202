/// <reference path="../../main.scss" />

.tnew-container {
    padding-bottom: $gap-sm-md;
    max-width: 1200px;

    .tn-subnav-component {
        margin-top: $gap-sm;
        margin-left: 0;

        @include media(small) {
            display: block;
        }

        & > div:not(.tn-cart-link):not(.tn-promo-box) {
            display: none;
        }


        .tn-link {
            display: none;
        }

        .tn-cart-link {
            flex: 0 1 auto;
            margin: 0;
        }

        .tn-timer {
            font-weight: $font-weight-bold;
            margin: 0;
        }
        /*.tn-timer-description {
            @include media(small) {
                display: none;
            }
        }*/
        .tn-promo-box {
            @include font-size($x-small-font-sizes);
            max-width: 25rem;
            display: block;
            margin: 0;

            button.tn-subnav-promo-button {
                @extend %btn-primary;
                @include font-size($x-small-font-sizes);
                margin: 0;
                padding: 8px 15px;
            }

            & > form {
                display: flex;
            }

            input {
                margin: 0;
                border: 1px solid $light-grey;
                width: 100%;
                padding: $space;
            }

            @include media(small) {
                margin-top: $gap-sm;
            }
        }
    }

    h1, .tn-heading {
        @include font-size($tnew-xlarge-font-sizes);
    }

    h2 {
        @include font-size($tnew-large-font-sizes);
    }
}

    .tn-heading {
        @include font-size($tnew-xlarge-font-sizes);
    }
.modal-open {
    .modal {
        &-title {
            @include font-size($larger-body-font-sizes);
        }

        &-footer {
            display: block !important;

            .btn + .btn {
                margin-bottom: 5px;
            }
        }
    }
}