/// <reference path="../../main.scss" />

.pattern-overlay, %pattern-overlay {
    &:after {
        background-image: url(~/Static/images/Pattern.png);
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: right center;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 20;
    }
}

.pattern-overlay .info {
    position: relative;
    z-index: 30;
}

.no-float {
    float: none !important;
}
