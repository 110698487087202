/// <reference path="../../../main" />
.mobile {
    &-nav {
        &-logo {
            padding-left: $gap-sm;
            @include media-min(medium) {
                display: none;
            }

            img {
                width: auto;
            }
        }

        &-toggles {
            display: flex;
            align-items: center;
           

            @include media-min(medium) {
                display: none;
            }
        }

        &-calendar {
            @include link-helper();
            svg {
                height: $ui-icon-size;
                width: $ui-icon-size;
            }
        }


    }
}

.nav {
    &-mobile { /// Mobile Nav header styling
        &-logo {
            padding-left: $gap-sm;

            @include media-min(medium) {
                display: none;
            }

            img {
                width: auto;
            }
        }

        &-utility {
            display: flex;
            justify-content: space-between;

            @include media(small) {
                flex-wrap: wrap;
            }

            @include media-min(medium) {
                display: none;
            }

            &-link {
                @extend %btn-white;
                width: calc((100% / 3 ) - (#{$gap-sm} * 2 / 3));
                padding-top: .625rem;
                padding-bottom: .625rem;
                min-width: 0;

                @include media(small) {
                    width: calc((100% / 2 ) - (#{$gap-sm} / 2));
                }


                svg {
                    margin-right: 5px;
                }
            }
        }

        &-toggles {
            display: flex;
            align-items: center;


            @include media-min(medium) {
                display: none;
            }
        }

        &-calendar {
            @include link-helper();
            position: relative;
            z-index: 300;
            width: 60px;
            height: 60px;
            display: block;
            text-decoration: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                height: $ui-icon-size;
                width: $ui-icon-size;
            }
        }
    }

    &-container {
        @include media(medium) {
            display: none;
        }


        .main {
            @include media(medium) {
                border-bottom: 1px solid rgba($grey, 0.5);
            }

            & > ul > li { // Top level list items for nav
                @include media(medium) {
                    background: $dark-blue;
                }




                &.has-child {
                    .sub-page-list > li {
                        @include media(medium) {
                            background-color: $true-white;
                        }
                    }

                    & > span > i { // Mobile subnav toggle
                        @include media(medium) {
                            height: 60px;
                            width: 60px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $true-white;
                            background: $c1;
                        }
                    }

                    .sub-page-list {
                        @include media(medium) {
                            display: none;
                        }
                    }

                    &.open {
                        .sub-page-list {
                            @include media(medium) {
                                display: block;
                            }
                        }

                        & > span > i { // Mobile subnav toggle
                            @include media(medium) {
                                justify-content: center;
                                color: $dark-blue;
                                background: $c2;
                            }

                            &:before {
                                @include media(medium) {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }

            & > ul li {
                @include media(medium) {
                    border-top: 1px solid rgba($grey, 0.5);
                }

                &.nav-feature {
                    @include media(medium) {
                        display: none;
                    }
                }


                > span {
                    @include media(medium) {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}
.reveal-out {
    .nav-container {
        display: block;
    }

    body {
        @include media(medium) {
            overflow: hidden;
        }
    }
}