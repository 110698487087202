/// <reference path="../../main.scss" />

.tnew-container {

    .tn-account-update-page, .tn-account-interests-page {
        .nav-tabs {
            display: flex;
            margin-left: -15px;
        }

        label {
            display: inline-block;
            margin-bottom: 0;
        }

        .tn-btn-grouped {
            @include media-min(mobile) {
                display: inline-block;
                width: auto;
            }

            & + .tn-btn-grouped {
                @extend %un-button;
                display: block;
                text-align: center;
                margin-top: $gap-sm;

                @include media-min(mobile) {
                    margin-top: 5px;
                    margin-left: $gap-sm;
                    display: inline-block;
                }
            }
        }
    }

    .tn-login-component, .tn-update-password-component {
        label {
            display: inline-block;
        }
    }

    .tn-account-login-forgot-login-link {
        @include link-helper();
    }
}