﻿/// <reference path="../../../main">
@mixin link-helper($color: $c1, $contrast: $dark-blue) {
    transition: .5s;
    color: $color;

    svg {
        fill: $color;
    }


    &:hover, &:active, &:focus {
        color: $contrast;
        text-decoration: none;

        svg {
            fill: $contrast;
        }


    }
}