/// <reference path="../../../main" />

%mega-hover { // meganav hover/show extendable class.
    @include media-min(medium) {
        border-bottom-color: $c2;
    }

    .meganav {
        @include media-min(medium) {
            transition-delay: .25s;
            opacity: 1;
            visibility: visible;
        }
    }
}

header {
    @include media(medium) {
        height: $mobile-nav-height;
    }
}


.nav {
    &-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media(medium) {
            border-bottom: 1px solid rgba($grey, 0.5);
        }
    }

    &-logo {
        display: block;
        position: absolute;
        top: 0;
        z-index: 50;

        img {
            width: auto;
        }

        @include media(medium) {
            display: none;
        }
    }

    &-utility {


        @include media-min(medium) {
            display: flex;
            justify-content: flex-end;
            height: 3.625rem;
            padding-right: 3rem;
        }


        &-inner {
            @include media-min(medium) {
                display: flex;
                align-items: center;
            }

            @include media(medium) {
                padding: $gap-md;
            }

            @include media(small) {
                padding: $gap-sm;
            }
        }

        &-list {
            display: flex;
            list-style: none;
            margin: 0;
            margin-right: $gap-sm-md;

            @include media(medium) {
                display: none;
            }

            &-item {
                & + & {
                    margin-left: $gap-sm-md;
                }
            }

            &-link {
                @include link-helper();
                font-weight: $font-weight-bold;
                @include font-size($x-small-font-sizes);
                display: flex;
                align-items: center;
                text-decoration: none;

                svg {
                    width: $ui-icon-size;
                    height: $ui-icon-size;
                    margin-right: 5px;
                }
            }
        }
    }

    &-search { // Search styling in the nav.
        position: relative;
        min-width: 1.125rem;

        &-toggle {
            @extend %un-button;
            @include link-helper();
            position: absolute;
            right: 0;
            top: 0;

            svg {
                width: $ui-icon-size;
                height: $ui-icon-size;
            }

            @include media(medium) {
                display: none;
            }
        }


        &-form {
            margin-bottom: $gap-sm;
            max-width: 31.25rem;
            margin-right: auto;
            margin-left: auto;

            @include media-min(medium) {
                width: 18.75rem;
                z-index: 10;
                transition: 0.5s width;
                margin-bottom: 0;
                margin-left: 0;
                margin-right: 0;
            }

            fieldset {
                margin-bottom: 0;
            }

            &-group {
                display: flex;
            }

            &-input {
                border-left: none;
                border-top: none;
                border-right: none;
                border-bottom: 1px solid $dark-blue;
                padding-left: $gap-sm;
                flex: 1 1 auto;
            }

            &-button {
                @extend %un-button;
                @include link-helper();

                svg {
                    width: $ui-icon-size;
                    height: $ui-icon-size;
                }
            }
        }

        &.hide-toggle {
            .nav-search-toggle {
                @include media-min(medium) {
                    display: none;
                }
            }
        }
    }

    &-calendar {
        @include link-helper($c5, $dark-blue);
        margin: 0;
        display: flex;
        align-items: center;

        svg {
            margin-right: 5px;
        }

        @include media(medium) {
            display: none;
        }
    }
}



.nav-container {

    a.nav-link { /// Link styling
        font-weight: $font-weight-bold;

        @include media(medium) {
            height: 3.75rem;
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            padding-left: $gap;
            text-decoration: none;
        }

        @include media(small) {
            padding-left: $gap-sm;
        }
    }

    .main {
        @include media-min(medium) {
            height: $base-nav-main-height_sm;
            background-color: $dark-blue;
            padding-left: 220px; // Moving main navigation link so that they're not under the logo
            display: flex;
            padding-right: 3rem;
            justify-content: space-between;
        }

        .top-nav-list {
            @include media-min(medium) {
                display: flex;
                flex-grow: 1;
            }


            & > li {
                @include media-min(medium) {
                    border-bottom: 4px solid transparent;
                }

                & > span {
                    @include media-min(medium) {
                        height: $base-nav-main-height_sm;
                        display: flex;
                        align-items: center;
                    }

                    & > .nav-link {
                        @include font-size($h5-font-sizes);
                        @include link-helper($true-white, $true-white);
                    }
                }

                & + li {
                    @include media-min(medium) {
                        margin-left: $gap-md;
                    }
                }

                .meganav {
                    @include media-min(medium) {
                        position: absolute;
                        background-color: $true-white;
                        top: 100%;
                        width: 100%;
                        right: 0;
                        padding-top: $gap-md;
                        padding-left: $gap;
                        padding-right: $gap;
                        padding-bottom: $gap-md;
                        opacity: 0;
                        visibility: hidden;
                        transition: all .15s ease-in-out;
                        box-shadow: 0 0 15px 0 rgba($true-black, 0.15);
                    }
                }

                &:hover {
                    @extend %mega-hover;
                }

                &:focus-within {
                    @include media-min(medium) {
                        border-bottom-color: $c2;
                    }

                    .meganav {
                        @include media-min(medium) {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }

    .has-child {
        i {
            @include media-min(medium) {
                color: $true-white;
                margin-left: $gap-sm/2;
                margin-top: $gap-sm/4;
                font-size: .75rem;
            }
        }

        .meganav {


            &-inner {
                @include media-min(medium) {
                    margin: 0 auto;
                    display: flex;
                }

                .nav-link {
                    @include font-size($static-small-font-sizes);

                    @include media-min(medium) {
                        font-weight: $font-weight-normal;
                    }
                }

                & > .sub-page-list {
                    &:first-child { // Styling for the mega-nav links section
                        @include media-min(medium) {
                            width: calc(25% - (#{$gap-md} / 2));
                            margin-right: $gap-md;
                        }

                        & > li {
                            @include media-min(medium) {
                                margin-bottom: $gap-sm;
                            }
                        }
                    }

                    &:nth-child(2) {
                        @include media-min(medium) {
                            width: calc(75% - (#{$gap-md} / 2));
                            display: flex;
                        }

                        .nav-feature {
                            &-header {
                                @include font-size($reg-font-sizes);
                                @include link-helper();
                            }

                            @include media-min(medium) {
                                width: calc(50% - (#{$gap-md} / 2));
                                max-width: $feature-width;
                            }

                            &:first-child {
                                margin-right: $gap-md;
                            }

                            a {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}


header { // Hiding desktop search form (only the header form).
    .nav-search-form-hidden {
        @include media-min(medium) {
            width: 0;
            overflow: hidden;
            z-index: -1;
        }
    }
}



.reveal-out {
    header {
        background-color: $true-white;

        @include media(medium) {
            bottom: 0;
            height: 100%;
            min-height: 100vh;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }

    .nav-container {
        display: block;

        @include media(medium) {
            overflow-y: scroll;
            /*max-height: calc(100vh - 130px);*/
        }
    }
}

.uskin-top-nav {
    display: none;
}


// iphone overwrites
.browser-ios {
    @include media(medium) {
        overflow: visible;
        
        #site {
            height: 100%;

            header {
                bottom: unset;
                max-height: none;
                height: auto;
                position: absolute;
                top: -3.75rem;
                min-height: none;

                .header-inner {
                    background: #fff;

                    .nav-container {
                        overflow-y: visible;
                        max-height: none;
                    }
                }
            }
        }
    }
}