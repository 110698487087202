/// <reference path="../../main.scss" />


    .tn-cart-item-summary {
        .tn-cart-item-summary__property--primary {
            &.tn-cart-item-summary__property--name { // Cart item name
                @extend %h3;
                font-weight: $font-weight-bold;
            }
        }

        .tn-cart-item-summary__property--secondary {
            @include font-size($reg-font-sizes);
        }
    }

    .tn-cart-item-detail__list {
        @include font-size($x-small-font-sizes);
        color: $dark-blue;
    }

.tn-cart-totals {
    .tn-cart-totals__line-item {
        & > * {
            color: $dark-grey;
            font-weight: $font-weight-normal;
        }

        .tn-cart-totals__label {

            @include font-size($larger-body-font-sizes);
        }

        .tn-cart-totals__value {
            @include font-size($reg-font-sizes);
        }
    }


    .tn-cart-totals__line-item--total {
        & > * {
            font-weight: $font-weight-bold;
            color: $dark-blue;
            @include font-size($larger-body-font-sizes);
        }
    }
}



.tn-cart-buttons {
    display: block;
    margin-bottom: $gap-sm-md;

    @include media-min(mobile) {
        display: flex !important;
        flex-direction: row-reverse !important;
        justify-content: flex-start !important;
        align-items: center !important;
    }

    .tn-cart-buttons__item-container {
        &:first-child {
            margin-bottom: $gap-sm;

            @include media-min(mobile) {
                margin-left: $gap-sm;
                margin-bottom: 0
            }
        }

        .tn-cart-buttons__primary-action {
            margin-bottom: 0;
        }

        @include media-min(mobile) {
            width: auto;
        }

        @include media(mobile) {
            text-align: center;
        }
    }
}

