/// <reference path="../../main.scss" />



%btn {
    border-radius: 0;
    border: 2px solid transparent;
    position: relative;
    padding: 12px 15px;
    margin-top: 5px;
    text-align: center;
    margin-bottom: 5px;
    @include font-size($reg-font-sizes);
    font-weight: $font-weight-bold;
    min-width: 8rem;
    text-decoration: none;

    svg {
        height: $ui-icon-size;
        width: $ui-icon-size;
    }

    @include media(mobile) {
        display: block;
        /*width: 100%;*/
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.6;

    }
}

.btn, .btn-primary, %btn-primary {
    @extend %btn;
    background-color: $c1;
    border-color: $c1;
    color: $c5;
    i {
        opacity: 1;
    }

    &:hover, &:active, &:focus, &:active:hover {
        background-color: $c5;
        color: $c1;
        border-color: $c1;
    }

    &[disabled] {
        &:hover, &:active, &:focus, &:active:hover { // these styles override TNEW disabled styles.
            background-color: $c1;
            border-color: $c1;
            color: $c5;
        }
    }
}

.btn-secondary, .btn-secondary, %btn-secondary {
    @extend %btn;
    background-color: $c2;
    border-color: $c2;
    color: $dark-blue;

    &:hover, &:active, &:focus, &:active:hover {
        border-color: $dark-blue;
        background-color: $c5;
    }
}

.btn-white, .btn-white, %btn-white {
    @extend %btn;
    background-color: $true-white;
    border-color: $c1;
    @include link-helper($c1, $true-white);

    &:hover, &:active, &:focus, &:active:hover {
        border-color: $c1;
        background-color: $c1;
    }
}

.btn-large, %btn-large {
    @extend %btn;
    @include font-size($lg-button-font-sizes);

    @include media-min(medium) {
        padding: $gap-sm;
    }
}

.un-button, %un-button {
    background: transparent;
    border: 0;
    min-width: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: inline;
    text-align: left;
    font-weight: normal;
    @include link-helper($c1, $dark-blue);
   

    &:hover, &:focus {
        background: transparent;
        border: 0;
    }
}