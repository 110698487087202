/// <reference path="../../main.scss" />



.promo-pods {
    .row {
        &.pods-wrapper {
            &.vert-align {
                @include media-min(small) {
                    display: flex;
                }

                .vert-align {
                    @include media-min(small) {
                        align-self: center;
                    }
                }
            }
        }

        .image {
            &.small-image {
                width: 75%;
                margin-left: auto;
                margin-right: auto;

                @include media(small) {
                    margin: 0 auto;
                }
            }
        }
    }
}
