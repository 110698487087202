/// <reference path="../../main.scss" />
$preheader-sizes: ( // Caption-text
null : (1rem, (1.75/1)), medium : (.75rem, (1.25/.75)) );

.card {
    &-pod {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &-container {
            @include media-min(mobile) {
                display: flex;
                flex-wrap: wrap;
            }
        }

        &.left-bg {
            background-image: url(~/Static/images/cardpods-left.svg);

            @include media(medium) {
                background-image: url(~/Static/images/cardpods-reflected.svg);
            }

            .info > .heading {
                color: $true-white;
            }
        }

        &.right-bg {
            background-image: url(~/Static/images/cardpods-right.svg);

            .info > .heading {
                color: $dark-blue;
            }
        }

        &-item {
            display: flex;



            &-inner {
                padding: $gap-sm-md;
                box-shadow: 0 0 10px 0 rgba($true-black, 0.15);
                background-color: $true-white;
                display: flex;
                width: 100%;

                &-content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    overflow: hidden;
                }
            }

            &-info {
            }

            &-preheader {
                @include font-size($preheader-sizes);
                text-transform: uppercase;
            }

            &-header {
                color: $dark-blue;
                margin-bottom: $gap-sm;
                margin-top: 0;
            }

            &-text {
                color: $dark-grey;

                > * {
                    color: $dark-grey;
                }
            }

            &-cta {
                margin-top: $gap-sm;


                @include media-min(small) {
                    display: flex;
                    align-items: flex-end;
                    margin-top: $gap-sm-md;
                    justify-content: space-between;
                }

                &-link {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                    margin-bottom: $space;

                    @include media-min(small) {
                        margin-right: $gap-sm-md;
                        margin-bottom: 0;
                    }
                }

                &-button {
                    white-space: normal;
                }

                &-link, &-button {
                    @include media(small) {
                        display: block;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.card-pod {
    &-container {
        > .items-4 {
            .card-pod-item-header {

                @include font-size($medium-font-sizes);
            }
        }
    }
}

