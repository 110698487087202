/// <reference path="../../main.scss" />



.banner {
    &-pattern {
        @extend %pattern-overlay;
    }

    .table-cell {
        > div {
            float: none;
        }
    }

    &-video {
        &-added {
            .video {
                display: none;

                @include media-min(small) {
                    display: block;
                }
            }

            .image {
                @include media-min(small) {
                    display: none;
                }
            }
        }

        &-embed {
            overflow: hidden;
            padding-top: 56.25%;
            width: 100%;
            height: 100%;

            &-inner {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border: 0;
            }
        }
    }



    &-cta {
        display: flex;
        clear: both;
        padding-left: 15px;
        padding-right: 15px;



        &-mobile {
            display: none;
        }
    }





    &-hours {
        &-header {
            color: $true-white;
        }

        background-color: $c1;
        color: $true-white;
        padding-top: $gap-md;
        padding-bottom: $gap-md;

        @include media(medium) {
            padding-top: $gap-sm-md;
            padding-bottom: $gap-sm-md;
        }

        .hours-text {
            display: none;
        }
    }
}


.home {
    &-banner {
        position: relative;

        &-inner {
            min-height: calc(100vh - #{$base-header-height_sm});
            display: flex;
            position: relative;


            &.overlay {
                &:before {
                    background-color: rgba($overlay-blue, 0.7);
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                }

                .home-banner-image-mobile {
                    &:before {
                        background-color: rgba($overlay-blue, 0.7);
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 0;
                    }
                }
            }

            &.banner-pattern {
                .home-banner-image-mobile {
                    @extend %pattern-overlay;
                }
            }
        }

        &-image {
            position: absolute;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @include media(small) {
                display: none;
            }

            &-mobile {
                display: none;

                @include media(small) {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    padding: $gap-sm;
                    display: block;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        &-text {
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 30;


            @include media-min(small) {
                padding-bottom: $gap;
                padding-top: $gap;
            }

            @include media(small) {
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 0;
            }

            &-inner {
                flex: 1 1 auto;
                display: flex;
                align-items: flex-end;
                position: relative;


                @include media-min(medium) {
                    align-items: center;
                }
            }

            &-content {
                @include media(small){
                    margin-bottom: $gap-sm
                }
            }
        }

        &-video {
            position: absolute;
            display: none;

            @include media(small) {
                display: none;
            }
        }

        &-cta {
            display: flex;
            justify-content: space-between;



            @include media(small) {
                display: block;
                padding: $gap-sm;
                background-color: $dark-blue;

                .link {
                    &:first-child {
                        margin-top: 0;
                    }
                }

                .btn {
                    display: block;
                }
            }

            .btn {
                @extend %btn-large;
            }
        }

       
    }
}