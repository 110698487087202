﻿/// <reference path="../../main.scss" />

/// parameters: size, line-height, letter-spacing (optional)
$x-large-font-sizes: ( //h1
    null : (5rem, (5.5 / 5)),
    medium : (2.5rem, (3/2.5))
);


$large-font-sizes: ( //h2
    null : (3.25rem, (3.75/3.25)),
    medium : (2rem, (2.5/2))
);

$medium-large-font-sizes: ( //h3
    null : (2.25rem, (2.25/1.75)),
    medium : (1.5rem, (2/1.5))
);

$medium-font-sizes: ( //h4
    null : (1.75rem, (2.25/1.75)),
    medium : (1.25rem, (1.75/1.25))
);

$h5-font-sizes: (
    null : (1.125rem, (1.75/1.25)),
    medium : (1rem, (1.5/1))
    );

$larger-body-font-sizes: (  //p.large
    null : (1.5rem, (2/1.5)), 
    medium : (1.125rem, (1.75/1.125))

);

$reg-font-sizes: (  //body
    null : (1.25rem, 1.75/1.25), 
    medium : (1rem, (1.5/1))
);



$small-font-sizes: ( //h6
    null : (1.125rem, (1.75/1.125)), 
    medium : (.875rem, (1.5/.875))
);

$x-small-font-sizes: ( // Caption-text
    null : (1rem, (1.5/1)), 
    medium : (.875rem, (1.5/.875))
);
$static-small-font-sizes: ( // Caption-text that doesnt change size
    null : (1rem, (1.5/1))
);
$lg-button-font-sizes: ( // Caption-text
    null : (1.75rem, (2.25/1.75)),
    medium : (1rem, (1.5/1))
);


/// TNEW Font adjustments
$tnew-xlarge-font-sizes: ( //h1s for tnew
    null : (3.5rem, (4 / 3.5)),
    medium : (2.5rem, (3/2.5))
);

$tnew-large-font-sizes: ( //h2
    null : (2.75rem, (3.25/2.75)),
    medium : (2rem, (2.5/2))
);

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
    @each $fs-breakpoint, $fs-font-size in $fs-map {
        @if $fs-breakpoint == null {
            @include _make-font-size($fs-font-size);
        }
        @else {
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }

            @media screen and (max-width: $fs-breakpoint) {
                @include _make-font-size($fs-font-size);
            }
        }
    }
}

@mixin _make-font-size($fs-font-size) {
    @if type-of($fs-font-size) == "list" {
        font-size: nth($fs-font-size, 1);

        @if (length($fs-font-size) > 1) {
            line-height: nth($fs-font-size, 2);
        }

        @if (length($fs-font-size) > 2) {
            letter-spacing: nth($fs-font-size, 3);
        }
    }
    @else {
        font-size: $fs-font-size;
    }
}