/// <reference path="../../../main.scss" />

// ------------- MEDIA QUERIES ------------- //

//@media (max-width:$x-large-media-query-size) 
$x-large-media-query-size: pxToEm(1600px);	 

//@media (max-width:$large-media-query-size)
$large-media-query-size: pxToEm(1200px);		

//@media (max-width:$medium-media-query-size)
$medium-media-query-size: pxToEm(992px);

//@media (max-width:$tablet-media-query-size)
$tablet-media-query-size: pxToEm(767px);	

//@media (max-width:$small-media-query-size)
$small-media-query-size: pxToEm(640px);	

//@media (max-width:$mobile-media-query-size)
$mobile-media-query-size: pxToEm(400px); 

// ------------- BREAKPOINTS MAP ------------- //

$breakpoints: ( 
    x-large: $x-large-media-query-size,
    large : $large-media-query-size, 
    medium: $medium-media-query-size, 
    tablet: $tablet-media-query-size, 
    small : $small-media-query-size,
    mobile : $mobile-media-query-size 
);



// ------------- Constants  ------------- //

$gap-lg: 5rem !default;
$gap: 2.5rem !default;
$gap-md: 2rem !default;
$gap-sm-md: 1.5rem;
$gap-sm: 1rem !default;
$space: .5rem !default;
$mobile-nav-height: 3.75rem;
$desktop-header-height: 7.5rem;
$ui-icon-size:  20px;
$feature-width: 28.125rem;


/* 	
--------------------------------------------------
1.0 COLOURS
-------------------------------------------------- 
*/
// ------------- FONTS ------------- //

$sans-serif-font: 'Montserrat', sans-serif;
$font-weight-normal: 500 !default;
$font-weight-bold: 700 !default;

/* THEME PALETTE */

$c1: #00A298;
$c2: #E8C43C;
$c3: #181818;
$c4: #f4f4f4;
$c5: #ffffff;
$c6: #e3e3e3;

/* BASE PALETTE */

$true-black: #000000;
$true-white: #ffffff;
$base-heading: #181818;
$grey: #ddddda;
$dark-grey: #5B6770;
$light-grey: #e3e3e3;
$tnew-error: #a94442;

$base-borders: #e3e3e3;
$form-errors: #a94442;
$dark-blue: #09434D;
$base-text: $dark-blue;
$overlay-blue: #2c333e;

/* 	
--------------------------------------------------
2.0 SIZES
-------------------------------------------------- 
*/
/* BASE */
$base-spacing: 30px;
$base-half-spacing: 15px;
$base-header-height: 90px;
$base-header-height_sm: 120px;
$base-nav-main-height_sm: 62px;
$base-nav-main-dropdown-width_sm: 240px;
$base-nav-main-dropdown-width-wider_sm: 480px;
$base-border-width: 1px;

/* APC */

$apc-spacing: 15px;
$apc-spacing_sm: 30px;
$apc-spacing_md: 45px;
$apc-spacing_lg: 60px;

/* ITEMS IN A ROW */

$items-1: 100%;
$items-2: 50%;
$items-3: 33.3333333%;
$items-4: 25%;

/* BANNERS */

$takeover-banner: calc(100vh - 90px);
$takeover-banner-ios: calc(100vh - 158px);
$takeover-banner_sm: calc(100vh - 160px);
$takeover-banner-ios_sm: calc(100vh - 186px);
$takeover-banner-ios-no-header: calc(100vh - 68px);
$takeover-banner-ios-no-header_sm: calc(100vh - 26px);
