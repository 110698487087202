/// <reference path="../../main.scss" />
.prefooter {


    &-container {
        position: relative;
        height: $gap;

        &:before {
            position: absolute;
            width: 25%;
            content: " ";
            height: 10px;
            background-color: $c2;
            bottom: 0;
            left: 15px;

            @include media(small) {
                width: calc(100% - 30px);
            }
        }
    }
}

.footer {
    background-color: $dark-blue;
    color: $true-white;

    &-info {
        &-header {
            font-weight: $font-weight-bold;
            margin-top: 0;
        }

        &-address {
            @include font-size($small-font-sizes);
        }
    }

    &-container {
        @include media-min(medium) {
            display: flex;
        }
    }

    &-contact {
        @include media-min(medium) {
            margin-right: $gap;
            width: calc((100% *(1/3)) - (#{$gap} / 2));
        }
    }

    &-links {
        @include media-min(medium) {
            width: calc((100% *(2/3)) - (#{$gap} / 2));
        }

        &-list {
            @include font-size($small-font-sizes);


            @include media-min(small) {
                column-gap: $gap-sm-md;
                column-count: 2;
            }

            @include media-min(medium) {
                column-count: 3;
            }

            & > li {
                margin-bottom: $gap-sm-md;

                a {
                    @include link-helper($true-white, $c1);
                }

                i {
                    display: none;
                }
            }
        }
    }

    &-social {
        &-list {
            margin-bottom: $gap;
            white-space: nowrap;

            & > li {
                display: inline-block;

                & + li {
                    margin-left: $space;
                }
            }

            a {
                display: flex;
                height: 2rem;
                width: 2rem;
                border-radius: 50%;
                background: $c1;
                justify-content: center;
                align-items: center;
                @include link-helper($true-white, $c1);

                svg {
                    height: 1rem;
                    width: 1rem;
                }

                &:hover {
                    background-color: $true-white;
                }
            }
        }
    }

    &-copyright {
        display: flex;

        @include media-min(medium) {
            justify-content: space-between;
        }

        @include media(medium) {
            flex-wrap: wrap;
        }

        > .copyright {
            order: -1;
            flex: 1 0 auto;

            @include media(medium) {
                order: 0;
                width: 100%;
                text-align: left;
            }
        }
    }

    &-policy {
        display: flex;
        flex: 1 0 auto;
        align-items: center;

        @include media-min(medium) {
            justify-content: flex-end;
        }

        @include media(medium) {
            width: 100%;
            flex-wrap: wrap;
        }

        &-links {
            margin: 0;
            display: flex;
            list-style: none;
            align-items: center;


            @include media(medium) {
                width: 100%;
                flex-wrap: wrap;
            }

            > li {
                margin-left: $gap-sm-md;

                &:first-child {
                    margin-left: 0;
                }

                @include media(medium) {
                    margin-left: 0;
                    width: 100%;
                }

                > a {
                    &:hover {
                        color: $true-white;
                    }
                }
            }
        }
    }

    &-email {
        max-width: 360px;
        width: 100%;
        margin-bottom: $base-spacing;

        &-form {
            display: flex;

            input {
                height: 40px;
                padding-top: $space;
                padding-bottom: $space;
                padding-left: $apc-spacing;
                padding-right: $apc-spacing;
                min-height: 0;
                width: calc(100% - 128px);
            }

            .btn {
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 0;
                padding-top: 0;
                width: 128px;
                text-align: center;
            }
        }

        @include media-min(medium) {
        }
    }
}
