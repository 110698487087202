/// <reference path="../../main.scss" />

.tnew-container {
    .tn-events-listing-page {
        .tn-events-keyword-filter { /// Keyword section styling
            .tn-event-listing__controls-heading {
                opacity: 1;
            }

            .btn {
                @include font-size($x-small-font-sizes);
            }

            .tn-events-keyword-filter__btn-clear-all-keywords {
                @extend %un-button;
                @include link-helper();
            }

            .tn-events-keyword-filter__keyword-button {
                opacity: .5;

                &:hover {
                    opacity: 1;
                    background: $c1;
                    color: $true-white;
                    border-color: $c1;
                }
            }

            .tn-events-keyword-filter__keyword-input {
                &:checked {
                    & + .tn-events-keyword-filter__keyword-button {
                        opacity: 1;
                        background: $c1;
                        color: $true-white;
                        border-color: $c1;
                    }
                }
            }
        }


        .tn-prod-list-item__perf-list-item {
            .tn-prod-list-item__perf-anchor {
                @include font-size($x-small-font-sizes);

                .glyphicon {
                    margin: 0;
                    min-width: 0;
                }
            }
        }

        .tn-btn-datepicker__btn-container {
            .tn-btn-datepicker__btn {
                @extend %btn-white;
                display: flex;
                margin: 0;
                height: auto;
                align-items: center;

                .tn-btn-datepicker__icon-container {
                    display: flex;

                    .glyphicon {
                        position: static;
                        color: $c1;
                    }
                }

                &:hover, &:focus {
                    .glyphicon {
                        color: $true-white;
                    }
                }
            }

            .tn-btn-datepicker__btn-period-prev-next {
                @extend %btn-primary;
                min-width: 0;
                margin: 0;
                border-radius: 0 5px 5px 0;

                &--btn-prev {
                    border-radius: 5px 0 0 5px;
                }
            }
        }

        .tn-events-calendar__event { // Event styling
            @extend %btn-primary;
            margin: 0;
            padding: 30px 10px 7px 5px;
            text-align: left;
            min-width: 0;
        }

        .tn-datepicker {
            @include font-size($x-small-font-sizes);

            .btn {
                min-width: 0;
            }

            td {
                padding: 2px;
            }

            .day {
                &.selected {
                    span {
                        background: $c1;
                        border-color: $c1;
                    }
                }
            }
        }

        .tn-events-calendar__day-event-list {
            margin-left: 0;
        }

        .tn-event-listing-mode-tab-nav__list-item {
            color: $c1;

            &.active {
                color: $dark-blue;
            }
        }

        .tn-event-listing-view__results-heading {
            color: $dark-blue;
        }
    }
}