﻿/// <reference path="../../../main">

@mixin focus() {
    outline: 2px solid transparent;
    outline-offset: 5px;
    transition: .5s all;

    &:focus {
        box-shadow: 0 0 0 2px                           $true-white;
        outline-offset: 2px;
        outline-color: $c1;
    }
}

%focus {
    @include focus();
}

a[href],
area[href],
input:not([disabled]),
select:not([disabled]),
textarea:not([disabled]),
button:not([disabled]),
iframe,
[tabindex],
[contentEditable=true] {
    &:not([tabindex='-1']) {
        @include focus();
    }
}